/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: EuclidSquare-Bold;
  src: url("assets/fonts/EuclidSquare-Bold.ttf") format("opentype");
}

@font-face {
  font-family: EuclidSquare-BoldItalic;
  src: url("assets/fonts/EuclidSquare-BoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: EuclidSquare-Italic;
  src: url("assets/fonts/EuclidSquare-Italic.ttf") format("opentype");
}

@font-face {
  font-family: EuclidSquare-Light;
  src: url("assets/fonts/EuclidSquare-Light.ttf") format("opentype");
}

@font-face {
  font-family: EuclidSquare-LightItalic;
  src: url("assets/fonts/EuclidSquare-LightItalic.ttf") format("opentype");
}

@font-face {
  font-family: EuclidSquare-Medium;
  src: url("assets/fonts/EuclidSquare-Medium.ttf") format("opentype");
}

@font-face {
  font-family: EuclidSquare-MediumItalic;
  src: url("assets/fonts/EuclidSquare-MediumItalic.ttf") format("opentype");
}

@font-face {
  font-family: EuclidSquare-Regular;
  src: url("assets/fonts/EuclidSquare-Regular.ttf") format("opentype");
}

@font-face {
  font-family: EuclidSquare-SemiBold;
  src: url("assets/fonts/EuclidSquare-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: EuclidSquare-SemiBoldItalic;
  src: url("assets/fonts/EuclidSquare-SemiBoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: WonderGardenSansRegular-Regular;
  src: url("assets/fonts/WonderGardenSansRegular-Regular.ttf")
    format("opentype");
}

@font-face {
  font-family: WonderGardenScript-Regular;
  src: url("assets/fonts/WonderGardenScript-Regular.ttf") format("opentype");
}
@font-face {
  font-family: FreightBigBookItalic-Regular;
  src: url("assets/fonts/FreightBigBookItalicRegular.ttf") format("opentype");
}

@font-face {
  font-family: Freight-Big-Light;
  src: url("assets/fonts/Freight-Big-Light.ttf") format("opentype");
}

@font-face {
  font-family: Freight-Big-Light-Italic;
  src: url("assets/fonts/Freight-Big-Light-Italic.ttf") format("opentype");
}

@font-face {
  font-family: Freight-Big-Book;
  src: url("assets/fonts/Freight-Big-Book.ttf") format("opentype");
}
@media only screen and (min-width: 980px) {
  :root {
    --spacing: 40px;
    --col: calc(10vw - var(--spacing-4) / 10);
  }
}
@media screen and (min-width: 1200px) and (max-width: 1440px) {
  :root {
    --spacing: calc(50px + 10 * (100vw - 1200px) / 240) !important;
  }
}

:root {
  --spacing: 20px;
  --spacing-0: 0px;
  --spacing-0-5: calc(var(--spacing) * 0.5);
  --spacing-0-7: calc(var(--spacing) * 0.7);
  --spacing-1: var(--spacing);
  --spacing-1-2: calc(var(--spacing) * 1.2);
  --spacing-1-5: calc(var(--spacing) * 1.5);
  --spacing-1-75: calc(var(--spacing) * 1.75);
  --spacing-1-9: calc(var(--spacing) * 1.9);
  --spacing-2: calc(var(--spacing) * 2);
  --spacing-2-25: calc(var(--spacing) * 2.25);
  --spacing-2-5: calc(var(--spacing) * 2.5);
  --spacing-2-75: calc(var(--spacing) * 2.75);
  --spacing-3: calc(var(--spacing) * 3);
  --spacing-3-25: calc(var(--spacing) * 3.25);
  --spacing-3-5: calc(var(--spacing) * 3.5);
  --spacing-3-75: calc(var(--spacing) * 3.75);
  --spacing-4: calc(var(--spacing) * 4);
  --spacing-4-5: calc(var(--spacing) * 4.5);
  --spacing-5: calc(var(--spacing) * 5);
  --spacing-5-5: calc(var(--spacing) * 5.5);
  --spacing-5-75: calc(var(--spacing) * 5.75);
  --spacing-6: calc(var(--spacing) * 6);
  --spacing-6-2: calc(var(--spacing) * 6.2);
  --spacing-6-5: calc(var(--spacing) * 6.5);
  --spacing-6-75: calc(var(--spacing) * 6.75);
  --spacing-7: calc(var(--spacing) * 7);
  --spacing-7-5: calc(var(--spacing) * 7.5);
  --spacing-8: calc(var(--spacing) * 8);
  --spacing-8-5: calc(var(--spacing) * 8.5);
  --spacing-9: calc(var(--spacing) * 9);
  --spacing-9-5: calc(var(--spacing) * 9.5);
  --spacing-10: calc(var(--spacing) * 10);
  --spacing-10-5: calc(var(--spacing) * 10.5);
  --spacing-11: calc(var(--spacing) * 11);
  --spacing-12: calc(var(--spacing) * 12);
  --spacing-12-5: calc(var(--spacing) * 12.5);
  --spacing-13: calc(var(--spacing) * 13);
  --spacing-14: calc(var(--spacing) * 14);
  --spacing-14-25: calc(var(--spacing) * 14.25);
  --spacing-15: calc(var(--spacing) * 15);
  --spacing-16: calc(var(--spacing) * 16);
  --spacing-18: calc(var(--spacing) * 18);
  --spacing-19: calc(var(--spacing) * 19);
  --spacing-20: calc(var(--spacing) * 20);
  --col: calc(25vw - var(--spacing-3) / 4);
}
html {
  height: 100%;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

mat-drawer-container {
  position: absolute !important;
  height: 100%;
  z-index: 1000 !important;
  width: 100%;
  height: 150px;
  background-color: transparent !important;
  .about-us-nav {
    background: #140e04 !important;
    @media screen and (min-width: 1400px) {
      & {
        width: calc(516px + 7vw) !important;
      }
    }
  }
  mat-drawer {
    width: 550px;
    &.mat-drawer-opened {
      position: fixed;
      background: #f8f3ef;
      & ~ mat-drawer-content span.navbar-toggler-icon {
        filter: invert(1) !important;
        position: relative !important;
        z-index: 3 !important;
      }
    }
  }
  .mat-drawer-content {
    z-index: unset !important;
  }

  @media screen and (min-width: 1400px) {
    mat-drawer {
      width: calc(316px + 7vw);
    }
  }

  @media screen and (max-width: 1399px) {
    mat-drawer {
      width: 550px;
    }
  }

  @media screen and (max-width: 560px) {
    mat-drawer {
      width: 100vw;
      height: 100vh;
    }
  }
}
.mat-drawer-backdrop.mat-drawer-shown {
  position: fixed;
  background-color: #1212129b;
}
// stepper
.mat-stepper-vertical {
  background: transparent;
  .mat-step-header:hover {
    background: transparent !important;
  }
  .mat-step-icon-selected {
    background: #e9bf62 !important;
  }
  .mat-vertical-content-container {
    &::before {
      border-left-color: #726e68 !important;
    }
  }
  .mat-step-icon-selected + .mat-step-label {
    color: #e9bf62 !important;
  }
  .mat-step-icon + .mat-step-label {
    font-family: Freight-Big-Book;
    color: #726e68;
  }
  .mat-step-icon {
    background: #726e68;
    .mat-step-icon-content {
      display: none;
    }
  }
  .mat-step + .mat-step-label {
    color: #e9bf62;
    font-family: Freight-Big-Book;
  }
}

.mat-slider {
  .mat-slider-track-wrapper {
    .mat-slider-track-fill {
      background-color: #e9bf62 !important;
    }
    .mat-slider-track-background {
      background-color: #695c3f;
    }
  }
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: #695c3f !important;
}
.mat-accent .mat-slider-thumb {
  background-color: #e9bf62 !important;
}

.mat-menu-content {
  padding: 0 !important;
}
.mat-select-arrow {
  color: #e9bf62;
}
.icon-btn {
  display: none;
  color: white;
  border-radius: 50px;
  border: 1px solid #e9bf62;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: 560px) {
    display: flex !important;
  }
}

*:focus-visible {
  outline: none;
}
[pointer] {
  cursor: pointer;
}

#home .owl-theme .owl-nav [class*="owl-"] {
  color: unset;
  background: unset !important;
  width: 7%;
  position: absolute;
  top: 75%;
  left: 60%;
}
#home .owl-theme .owl-nav .owl-next {
  left: 70% !important;
}
#home .owl-theme .owl-nav [class*="owl-"] img {
  width: 100%;
  height: 70%;
  object-fit: contain;
}

.mat-form-field-flex {
  background-color: transparent !important;
}
.mat-form-field-appearance-fill .mat-form-field-underline::before,
.mat-form-field-appearance-fill.mat-form-field-disabled
  .mat-form-field-underline::before {
  background-color: white;
}
.mat-select-value-text,
.mat-form-field-label,
.mat-input-element:disabled,
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: #e9bf62 !important;
}

.mat-menu-panel {
  min-width: 200px !important;
  max-width: 335px;
  padding: 10px;
}
.ngx-slider .ngx-slider-pointer,.ngx-slider .ngx-slider-selection {
  background-color: #e9bf62 !important;
}
